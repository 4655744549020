import React from "react"
import { graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons/faArrowUpRight"
import Layout from "components/layout"
import { Container, Section } from "../components/Layouts"
import SEO from "components/seo"
import { Image, Text } from "../components/Core"
import UpRightArrow from "../../static/images/up-right-arrow.svg"
import Contact from "../components/Services/contact"
import MobileCarousel from "../components/Carousels/MobileCarousel"
import Button from "../components/Buttons/Button"

const BlogsQuery = ({ data, path, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const insights = data.allInsightsJson.nodes
  const [firstInsight, ...rest] = insights

  const nextFourInsights = rest.slice(0, 4)
  const restInsights = rest.slice(4, rest.length)

  const { heading } = post

  return (
    <Layout navSpacer className="insights" path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <Section zeroTop>
        <Container medium>
          <div className="insights__hero">
            <Text as="h1" text={heading} />
            <UpRightArrow />
          </div>
        </Container>
      </Section>

      <Section zeroTop>
        <Container medium>
          <div className="insights__featured">
            <Image
              width="661"
              height="441"
              wrapperClassName="rounded"
              publicId={firstInsight.heroImage}
            />
            <div className="insights__card">
              <Text
                as="p"
                className="label color-blue"
                text={firstInsight.label}
              />
              <Text as="h2" className="title" text={firstInsight.heading} />
              <Text as="p" className="blurb" text={firstInsight.query.blurb} />
              <Link to={firstInsight.title} className="link">
                <FontAwesomeIcon icon={faArrowUpRight} />
                Learn More
              </Link>
            </div>
          </div>
        </Container>
      </Section>

      <Section zeroTop className="is-hidden-touch">
        <Container medium>
          <div className="insights__grid-2">
            {nextFourInsights.map((insight, i) => (
              <div
                key={insight.title}
                className="insights__minor insights__grid-2">
                <div className="card-image">
                  <Image
                    width="283"
                    height="210"
                    useAR={false}
                    className="rounded"
                    publicId={insight.heroImage}
                  />
                </div>
                <div className="insights__card">
                  <Text
                    as="p"
                    className="label color-blue"
                    text={insight.label}
                  />
                  <Text as="h2" className="title" text={insight.heading} />
                  <Link
                    to={insight.title}
                    className="link"
                    title="Read the full article">
                    <FontAwesomeIcon icon={faArrowUpRight} />
                    Learn More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      {/* MOBILE VIEW */}
      <Section className={"is-hidden-desktop"}>
        <MobileCarousel>
          {nextFourInsights.map((insight, i) => (
            <div key={insight.title} className="insights__minor mobile-card">
              <div className="card-image">
                <Image
                  width="381"
                  height="148"
                  className="card-image rounded"
                  publicId={insight.heroImage}
                />
              </div>
              <div className="insights__card">
                <Text
                  as="p"
                  className="label color-blue"
                  text={insight.label}
                />
                <Text as="h2" className="title" text={insight.heading} />
                <Button
                  href={insight.title}
                  buttonText="Learn More"
                  appearance="regular"
                  destination="internal"
                />
              </div>
            </div>
          ))}
        </MobileCarousel>
      </Section>
      {/* MOBILE VIEW */}

      <Section zeroTop className="is-hidden-touch">
        <Container medium>
          <div className="insights__grid-3">
            {restInsights.map((insight, i) => (
              <div key={insight.title}>
                <div className="insights__card">
                  <Text
                    as="p"
                    className="label color-blue"
                    text={insight.label}
                  />
                  <Text as="h2" className="title" text={insight.heading} />
                  <Text as="p" className="blurb" text={insight.query.blurb} />
                  <Link to={insight.title} className="link">
                    <FontAwesomeIcon icon={faArrowUpRight} />
                    Learn More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      {/* MOBILE VIEW */}
      <Section className={"is-hidden-desktop"}>
        <MobileCarousel>
          {restInsights.map((insight, i) => (
            <div key={insight.title} className="insights__minor mobile-card">
              <div className="card-image">
                <Image
                  width="381"
                  height="148"
                  className="rounded"
                  publicId={insight.heroImage}
                />
              </div>
              <div className="insights__card">
                <Text
                  as="p"
                  className="label color-blue"
                  text={insight.label}
                />
                <Text as="h2" className="title" text={insight.heading} />
                <Button
                  href={insight.title}
                  buttonText="Learn More"
                  appearance="regular"
                  destination="internal"
                />
              </div>
            </div>
          ))}
        </MobileCarousel>
      </Section>
      {/* MOBILE VIEW */}

      <Section zeroBottom>
        <Container medium>
          <Contact />
        </Container>
      </Section>
    </Layout>
  )
}

export default BlogsQuery

export const pageQuery = graphql`
  query blogsQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
      }
    }
    allInsightsJson(
      filter: { hasThisPage: { eq: true } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        title
        heading
        heroImage
        label
        query {
          blurb
        }
      }
    }
  }
`
